import './styles.scss'

export default function Steps(props) {
    return (
        <svg className='steps' viewBox="0 0 250 260" width="100%" height="100%" style={{
            '--color': `var(--${props.color || 'cyan'})`
        }}>

            <rect className='line' x="7" y="30" width="1" height="220"></rect>

            <g className='step-1-bullet'>
                <circle className='outer-bullet' cx="7.5" cy="36" r="7" role="presentation"></circle>
                <circle className='inner-bullet' cx="7.5" cy="36" r="3" role="presentation"></circle>
            </g>

            <g className='step-2-bullet'>
                <circle className='outer-bullet' cx="7.5" cy="101" r="7" role="presentation"></circle>
                <circle className='inner-bullet' cx="7.5" cy="101" r="3" role="presentation"></circle>
            </g>

            <g className='step-2-bullet'>
                <circle className='outer-bullet' cx="7.5" cy="157" r="7" role="presentation"></circle>
                <circle className='inner-bullet' cx="7.5" cy="157" r="3" role="presentation"></circle>
            </g>

            <text className='heading' x="0" y="10">ZERO Is Easy & Saves Money</text>

            {/* Step 01 */}

            <text className='step' x="25" dy="40">
                Step <tspan>01</tspan>
            </text>

            <text className='body'>
                <tspan x="25" dy="55">Plan members <tspan className='bold'>connect with their Personal Health Assistant</tspan></tspan>
                <tspan x="25" dy="1.5em">(yes, they get a real human assistant) to see if the procedure</tspan>
                <tspan x="25" dy="1.5em">or service they need is covered</tspan>
            </text>

            {/* Step 02 */}

            <text className='step'  x="25" dy="105">
                Step <tspan>02</tspan>
            </text>

            <text className='body'>
                <tspan x="25" dy="120">We help match plan members up with <tspan className='bold'>high-quality, low-cost</tspan></tspan>
                <tspan className='bold' x="25" dy="1.5em">providers in their area</tspan>
            </text>

            {/* Step 03 */}

            <text className='step' x="25" dy="160">
                Step <tspan>03</tspan>
            </text>

            <text className='body'>
                <tspan x="25" dy="175"><tspan className='bold'>Employers save</tspan> up to 50% compared to the average costs in</tspan>
                <tspan x="25" dy="1.5em">legacy PPO models</tspan>
            </text>

            <text className='body'>
                <tspan x="25" dy="205"><tspan className='bold'>Members save</tspan> their hard earned cash and get access to the</tspan>
                <tspan x="25" dy="1.5em">care they need for $0</tspan>
            </text>

            <text className='body'>
                <tspan className='bold' x="25" dy="235">Happy, happy people and hi-fives all around!</tspan>
            </text>
        </svg>
    )
}