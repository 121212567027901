import './styles.scss'

export default function Features(props) {
    return (
    <svg className="features" width="100%" height="100%" viewBox="0 0 250 170">

        <g className="feat-1">
            <rect x="0" width="6%" height="2%" />
            <text>
                <tspan className="txt-heading" x="0" y="12%">Customer</tspan>
                <tspan className="txt-body" x="0" dy="9%">We work with self-funded</tspan>
                <tspan className="txt-body" x="0" dy="7%">health plans and the healthcare</tspan>
                <tspan className="txt-body" x="0" dy="7%">providers delivering care</tspan>
            </text>
        </g>

        <g className="feat-2">
            <rect x="52%" width="6%" height="2%" />
            <text x="0">
                <tspan className="txt-heading" x="52%" y="12%">Feature Set</tspan>
                <tspan className="txt-body" x="52%" dy="9%">Our Personal Health Assistants</tspan>
                <tspan className="txt-body" x="52%" dy="7%">help match plan members with</tspan>
                <tspan className="txt-body" x="52%" dy="7%">participating providers</tspan>
            </text>
        </g>

        <g className="feat-3">
            <rect x="0" y="50%" width="6%" height="2%" />
            <text x="0">
                <tspan className="txt-heading" x="0" y="62%">Value Prop</tspan>
                <tspan className="txt-body" x="0" dy="9%">We make sure plan</tspan>
                <tspan className="txt-body" x="0" dy="7%">members have access to</tspan>
                <tspan className="txt-body" x="0" dy="7%">care for $0 and help health</tspan>
                <tspan className="txt-body" x="0" dy="7%">plans lower costs up to 50%</tspan>
            </text>
        </g>

        <g className="feat-4">
            <rect x="52%" y="50%" width="6%" height="2%" />
            <text>
                <tspan className="txt-heading" x="52%" y="62%">Business Model</tspan>
                <tspan className="txt-body" x="52%" dy="9%">We make money by charging a</tspan>
                <tspan className="txt-body" x="52%" dy="7%">simple (and transparent)</tspan>
                <tspan className="txt-body" x="52%" dy="7%">transaction fee every time we</tspan>
                <tspan className="txt-body" x="52%" dy="7%">process a payment</tspan>
            </text>
        </g>

    </svg>
    )
  }