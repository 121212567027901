import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect, useState } from 'react'
import CompLogo from '../components/Logo/'
import CompTitle from '../components/Title/'
  
export default function App(props) {
    const [height, setHeight] = useState(0)

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "company",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "logo",
                type: "column",
                source: "source",
                allowMultiple: false
            },
            {
                name: "title",
                type: "text",
                placeholder: "Title Text"
             }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { title, company, logo } = config
    const txt = (title) ? title : 'Report Title'
    const companyTxt = (Object.keys(sigmaData).length && company) ? sigmaData[company][0] : ''
    const logoPath = (Object.keys(sigmaData).length && logo) ? sigmaData[logo] : [null]
    
    const img = new Image()
    img.src = logoPath[0]
    img.onload = (e) => {
        const ratio = e.target.width / e.target.height
        let h = 30

        if(ratio < 2) h = 80
        if(ratio > 2 && ratio < 10) h = 60

        setHeight(h)
    }

    return (
        <header className='header' style={{
            display: 'grid',
            alignItems: 'center',
            gap: '2em',
            gridTemplateColumns: '11.5rem 1fr auto',
            padding: '0 var(--gap-sm)'
        }}>
            <CompLogo />
            <CompTitle txt={txt}/>
            
            {!logoPath[0] &&  
            <h3 
            style={{
                margin: 0,
                textAlign: 'right'
            }}>{companyTxt}</h3>
            }
            
            {logoPath[0] &&  
            <img style={{
                height: height + 'px',
                maxWidth: 'auto',
                width: 'auto'
            }} src={logoPath[0]} alt={companyTxt}></img>
            }
        </header>
    )
}
