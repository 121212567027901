import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect, useState } from 'react'
import CompMap from '../components/Map'
  
export default function Markets() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "hrrCode",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "marketType",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "title",
                type: "text"
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)
    const { hrrCode, marketType, title } = config
    const [marketData, setMarketData] = useState()

    useEffect(() => {
        const obj = { 
            primary: [],
    
            legend: [
                {
                    color: 'raspberry',
                    label: 'Primary Market'
                },
            
                {
                    color: 'white',
                    label: 'Secondary Market'
                }
            ]
        }

        if(Object.keys(sigmaData).length && hrrCode && marketType) {
            sigmaData[marketType].forEach((item, idx) => {
                if(item.toLowerCase() === 'primary') {
                    obj.primary.push(sigmaData[hrrCode][idx])
                }
            })

            setMarketData(obj)
        }
    }, [hrrCode, sigmaData, marketType])

    if(marketData === undefined) return null

    return (
        <CompMap 
        id="markets"
        title={title || 'ZERO Markets Map'}
        type='hrr'
        market={marketData}
        />
    )
}
