import CompSteps from '../components/Steps/'
import { client, useConfig } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import { colorsArr } from '../utilities'
  
  export default function App() {
    
    useEffect(() => {
      client.config.configureEditorPanel([
        {
            name: 'color',
            type: 'dropdown',
            values: colorsArr
        }
      ])
    }, [])

    const config = useConfig()
    const { color } = config

    return (
        <CompSteps color={color} />
    )
  }