const colorsArr = [
    ' ',
    'blue-jeans',
    'raspberry',
    'orange-slice',
    'cyan',
    'blueberry',
    'tuscany',
    'blue-skies',
    'eggplant',
    'cherry',
    'ink-black',
    'clouds',
    'white'
]

function toUSD(val, decPlace = 0, isCompact = false) {
    return new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'currency',
      notation: (isCompact) ? 'compact' : 'standard',
      compactDisplay: 'short',
      maximumFractionDigits: (val < 1000 && isCompact) ? 0 : decPlace,
    }).format(val)
}

function toFormattedNumber(val, decPlace = 0, isCompact = false) {
    return new Intl.NumberFormat(undefined, {
        maximumFractionDigits: decPlace,
        notation: (isCompact) ? 'compact' : 'standard',
        compactDisplay: 'short',
    }).format(val)
}

function toPercent(val, decPlace = 0) {
    return `${(val * 100).toFixed(decPlace)}%`;
}

function renderFormattedNum(num, isCurrency = false, decPlace = 0, isCompact = false) {
    let val = 0

    if(isCurrency) {
        val = toUSD(num, decPlace, isCompact)
    }

    if(!isCurrency) {
        val = toFormattedNumber(num, decPlace, isCompact)
    }

    return val
}

function _setWidthBasedVars(targetElem, baseVal) {
    const vars = {
        '--hs-body': `clamp(1rem, calc(${baseVal}px * 0.028), 30rem)`,
        '--hs-h1': `calc(var(--hs-body) * 3)`,
        '--hs-h2': `calc(var(--hs-body) * 2)`,
        '--hs-h3': `calc(var(--hs-body) * 1.6)`,
        '--s-size': `${baseVal}px`
    }
    
    Object.keys(vars).forEach(key => {
        targetElem.style.setProperty([key], vars[key])
    })    
}

function scaleContentOnWidth() {
   const nodes = document.querySelectorAll('.scale-on-width')
   const observer = new ResizeObserver((entries) => {
       entries.forEach((entry) => {
           _setWidthBasedVars(entry.target, entry.contentRect.width)
       })
   })

   nodes.forEach(item => {
    observer.observe(item)
   })
}

function createLines(str) {
    const arr = []
    str.split(/\n/).forEach((line, index) => {
        const lineHeight = (index === 0) ? 'y="50%"' : 'dy="1.1em"'
        arr.push(`<tspan x="50%" ${lineHeight} text-anchor="middle">${line.replace(/^\s/, '')}</tspan>`)
    })

    return {__html: arr.join('')}
}

function wrapText(str, limit = 16) {
    const wordsArr = str.split(/\s/)
    let newStr = ''
    let count = 0

    wordsArr.forEach((word, index) => {
        count += word.length

        // If first word is at or over limit then append word and add new line break
        if(index === 0 && count >= limit) {
            count = 0
            newStr += `${word}\n`
            return
        }

        // If there is a word after current index and the combined length is at or over limit then append current word and add new line break
        if(wordsArr[index + 1] && count + wordsArr[index + 1].length >= limit) {
            count = 0
            newStr += ` ${word}\n`
            return 
        }

        newStr += ` ${word}`
    })

    return createLines(newStr)
}

function truncate(str, limit = 31) {
    if(str.length > limit) return str.substring(0, limit) + '...'
    return str
}

function getMinValue(arr, key) {
    return arr.reduce((prev, curr) => {
        return prev[key] < curr[key] ? prev : curr;
    })
}

function getMaxValue(arr, key) {
    return arr.reduce((prev, curr) => {
        return prev[key] > curr[key] ? prev : curr;
    })
}

function roundDownToNearest10(num) {
    if(num < 100) return num
    return Math.floor(num / 10) * 10
}

function makeRangeArr(arr, key, increments = 10) {
    const min = 1
    const max = getMaxValue(arr, key)[key]
    const minMaxOffset = 2
    const incr = Math.floor((max - min) / (increments - minMaxOffset))
    const tmp = []
    let i = 0
    
    tmp.push(min)
    while(i < (increments - minMaxOffset) && tmp[tmp.length - 1] < max) {
        tmp.push((roundDownToNearest10(tmp[tmp.length - 1] + incr)))
        i++
    }
    tmp.push(max)

    return tmp
}

export {
    toUSD,
    toPercent,
    scaleContentOnWidth,
    wrapText,
    renderFormattedNum,
    truncate,
    colorsArr,
    getMinValue,
    getMaxValue,
    makeRangeArr
}

