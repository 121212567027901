import './styles.scss'

export default function ClientLogo(props) {
    return (
        <div className='client-logo'>
            {(props.src) &&
            <div className={`logo-wrap ${props.grayscale && 'grayscale'}`}>
                <img src={props.src} alt={props.label}></img>
            </div>
            }
            {(props.label) && <p>{props.label}</p> }
        </div>
    )
}