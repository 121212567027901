import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect, useState } from 'react'
import CompMap from '../components/Map'
  
export default function Choropleth() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "id",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "val",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "colorTheme",
                type: "dropdown",
                values: [
                    ' ',
                    'raspberry',
                    'cyan',
                    'orange-slice'
                ],
                source: "source"
            },
            {
                name: "markets",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "colorThemeSecondary",
                type: "dropdown",
                values: [
                    ' ',
                    'raspberry',
                    'cyan',
                    'orange-slice'
                ],
                source: "source"
            },
            {
                name: "title",
                type: "text"
            },
            {
                name: "mapType",
                type: "dropdown",
                values: [
                    ' ',
                    'hrr',
                    'county',
                    'state'
                ]
            },
            {
                name: "currency",
                type: "toggle"
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)
    const { title, mapType, colorTheme, currency, id, val, markets, colorThemeSecondary } = config
    const [mapData, setMapData] = useState()

    useEffect(() => {
        if(Object.keys(sigmaData).length > 1 && id && val) {
            const md = []
            sigmaData[id].forEach((item, idx) => {
                const obj = {
                    id: item,
                    val: sigmaData[val][idx]
                }
                
                if(markets) obj['inMarket'] = sigmaData[markets][idx]
    
                md.push(obj)
            })
    
            setMapData(md)
        }
    }, [sigmaData, id, val, markets])

    if(mapData === undefined) return null

    return (
        <CompMap 
        id="choropleth"
        title={title}
        choropleth={mapData}
        type={mapType}
        color={colorTheme}
        currency={currency}
        secondaryColor={colorThemeSecondary}
        />
    )
}
