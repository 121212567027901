import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompDot from '../components/Dot/'
import { colorsArr } from '../utilities'
  
export default function App() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "value",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: 'label',
                type: 'text',
                placeholder: 'Label'
            },
            {
                name: 'bgColor',
                type: 'dropdown',
                values: colorsArr
            },
            {
                name: 'percentage',
                type: 'toggle'
            },
            {
                name: 'currency',
                type: 'toggle'
            },
            {
                name: 'textColor',
                type: 'dropdown',
                values: colorsArr
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { value, label, bgColor, textColor, percentage, currency } = config
    const val = (Object.keys(sigmaData).length && value) ? sigmaData[value][0] : null
    
    return (
         <CompDot 
            val={val} 
            percent={percentage} 
            currency={currency}
            label={label} 
            bgColor={bgColor} 
            textColor={textColor}
            height="100%" />
    )
}
