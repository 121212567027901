import { useState } from 'react';
import './styles.scss'

export default function Card(props) {

    const [label, setLabel] = useState('Copy URL')

    const span = (props.fullwidth) ? '-1 / 1' : 'auto'

    const copy = (e) => {
        e.preventDefault()

        navigator.clipboard.writeText(`https://sigma-plugins.zero.health/${props.path}`).then(() => {
            setLabel('Copied!')
            setTimeout(() => setLabel('Copy URL'), 4000)
        })
    }

    return (
        <div className='ui-card' style={{
            gridColumn: span
        }}>
            <div className='card-content'>
                {props.children}
            </div>

            <footer>
                <span>{props.title ?? 'Untitled'}</span>
                <a href={`https://sigma-plugins.zero.health/${props.path}`} onClick={copy}>{label}</a>
            </footer>
        </div>
    )
}