import './styles.scss'
import { renderFormattedNum } from '../../utilities'
import Loading from '../../ui/Loading'

export default function Metric(props) {
    const value = renderFormattedNum(props.val, props.currency, props.decimal, props.compact)
    
    return (
        <>
            {props.val == null && <Loading /> }
            <svg className='metric' viewBox="0 0 180 35" width="100%" height="100%">
                <text>
                    <tspan className="label" textAnchor='middle' x="50%" y="12" fill={`var(--${props.labelColor || 'blue-jeans'})`}>{props.label || 'No Label'}</tspan>
                    <tspan className="value" textAnchor='middle' x="50%" dy="50%" fill={`var(--${props.valueColor || 'blue-jeans'})`}>{value}</tspan>
                </text>
            </svg>
        </>
    )
}