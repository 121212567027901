import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import Cloud from '../components/Cloud'

export default function WordCloud() {
    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "text",
                type: "column",
                source: "source",
                allowMultiple: false
            }
        ])
    })
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)
    const { text } = config
    let str = ''

    if(Object.keys(sigmaData).length) str = sigmaData[text].join(' ')

    return (
        <Cloud string={str}></Cloud>
    )
}