import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompMetric from '../components/Metric/'
import { colorsArr } from '../utilities'
  
export default function App() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "value",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: 'label',
                type: 'text',
                placeholder: 'Label'
             },
             {
                name: 'labelColor',
                type: 'dropdown',
                values: colorsArr
             },
             {
                 name: 'currency',
                 type: 'toggle'
             },
             {
                name: 'decimalPlaces',
                type: 'text',
                defaultValue: '2'
             },
             {
                name: 'showCompact',
                type: 'toggle'
            },
            {
                name: 'valueColor',
                type: 'dropdown',
                values: colorsArr
             }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { value, label, labelColor, valueColor, currency, decimalPlaces, showCompact } = config
    const val = (Object.keys(sigmaData).length && value) ? sigmaData[value][0] : null

    return (
        <CompMetric 
            val={val} 
            label={label} 
            labelColor={labelColor}
            valueColor={valueColor}
            currency={currency}
            decimal={decimalPlaces}
            compact={showCompact} />
    )
}
