import './styles.scss'
import { toPercent, renderFormattedNum } from '../../utilities'
import Loading from '../../ui/Loading'

export default function Dot(props) {
    const value = (props.percent) ? toPercent(props.val) : renderFormattedNum(props.val, props.currency, (props.val > 1000 && props.val < 10000) ? 1 : 0, true)

    return (
      <>
        {props.val == null && <Loading /> }
        <svg className='dot' height={props.height} width="100%" viewBox="0 0 40 40" role="img">

          <circle className="circle" cx="20" cy="20" r="18" role="presentation" fill={`var(--${props.bgColor || 'raspberry'})`}></circle>

          <g className="text" fill={`var(--${props.textColor || 'white'})`}>
              <text x="50%" y="54%" textAnchor="middle" className="value">{value}</text>
              <text x="50%" y="66%" textAnchor="middle" className="label">{props.label || 'No Label'}</text>
          </g>

        </svg>
      </>
    )
  }