import { useEffect } from 'react'
import d3Cloud from 'd3-cloud'
import * as d3 from 'd3'
import { scaleLinear } from 'd3-scale'
import { colors } from '../../plugins/colors'
import Loading from '../../ui/Loading'

export default function Cloud(props) {
    useEffect(() => {
        
        // Array of determiner and conjunction words, along with any custom additions like "zero" to be filtered out
        const filterOut = ["a", "able", "about", "across", "after", "all", "almost", "also", "am", "among", "an", "and", "any", "are", "as", "at", "be", "because", "been", "but", "by", "can", "cannot", "could", "dear", "did", "do", "does", "either", "else", "ever", "every", "for", "from", "get", "got", "had", "has", "have", "he", "her", "hers", "him", "his", "how", "however", "i", "if", "in", "into", "is", "it", "its", "just", "least", "let", "like", "likely", "may", "me", "might", "most", "must", "my", "neither", "no", "nor", "not", "of", "off", "often", "on", "only", "or", "other", "our", "own", "rather", "said", "say", "says", "she", "should", "since", "so", "some", "than", "that", "the", "their", "them", "then", "there", "these", "they", "this", "tis", "to", "too", "twas", "us", "wants", "was", "we", "were", "what", "when", "where", "which", "while", "who", "whom", "why", "will", "with", "would", "yet", "you", "your", "ain't", "aren't", "can't", "could've", "couldn't", "didn't", "doesn't", "don't", "hasn't", "he'd", "he'll", "he's", "how'd", "how'll", "how's", "i'd", "i'll", "i'm", "i've", "isn't", "it's", "might've", "mightn't", "must've", "mustn't", "shan't", "she'd", "she'll", "she's", "should've", "shouldn't", "that'll", "that's", "there's", "they'd", "they'll", "they're", "they've", "wasn't", "we'd", "we'll", "we're", "weren't", "what'd", "what's", "when'd", "when'll", "when's", "where'd", "where'll", "where's", "who'd", "who'll", "who's", "why'd", "why'll", "why's", "won't", "would've", "wouldn't", "you'd", "you'll", "you're", "you've", "&", "zero", "card", "zerocard"]

        const wordLimit = 250
        const wordsData = getWords(wordLimit)
        const wordCount = wordsData.length
        const width = wordCount * 3
        const height = Math.round(width * (3/4)) //4:3 aspect ratio
        
        function getWords(limit) {
            const cleanedStr = props.string
                                .toLowerCase()
                                .replace(/\b(-)/g, '') //remove dashes next to letters
                                .replace(/[!.,:;?$"()\\]/g, ' ') // remove punctuation
                                .replace(/\s\s+/g, ' ') //remove more than single spaces
                                
            const rawArr = cleanedStr.split(' ').filter(word => !filterOut.includes(word))
            const uniqueArr = [...new Set(rawArr)]

            return uniqueArr.map(word => {
                if(!word) return {}
                
                return {
                    text: word,
                    value: rawArr.filter(item => word === item).length
                }
            })
            .sort((a, b) => b.value - a.value)
            .slice(0, limit)
        }

        function getFill() {
            const fills = [
                colors.blueJeans,
                colors.orangeSlice,
                colors.raspberry,
                colors.cyan,
                colors.blueberry,
                colors.eggplant,
                colors.blueSkies,
                colors.ocean
            ]

            return fills[Math.floor(Math.random() * (fills.length - 1))]
        }

        function makeCloud(selector) {
            document.querySelector(`${selector} > g`)?.remove()

            const svg = d3.select(selector)
                .attr('viewBox', `0 0 ${width} ${height}`)
                .append('g')
                .attr('transform', `translate(${width / 2} ${height / 2})`)

            function draw(words) {
                const cloud = svg.selectAll('g text').data(words, (d) => d.text )
           
                //Entering words
                cloud.enter()
                    .append('text')
                    .style('font-family', 'Raleway')
                    .style('fill', () => getFill())
                    .attr('text-anchor', 'middle')
                    .attr('font-size', (d) => d.size)
                    .attr('font-weight', '800')
                    .text((d) => d.text )
                    .transition()
                    .duration(600)
                    .style('font-size', (d) => `${d.size}px` )
                    .attr('transform', (d) => `translate(${d.x}, ${d.y}) rotate(${d.rotate})`)
                    .style('fill-opacity', 1)
            }

            const dataMax =  d3.max(wordsData, (d) => d.value )
            const sizeScale = scaleLinear().domain([0, dataMax]).range([0, 1])

            const multiplier = (wordCount < 125) ? 30 : 90

            // Render word cloud
            d3Cloud()
            .size([width, height])
            .words(wordsData)
            .font('Raleway')
            .fontWeight('800')
            .fontSize((d) => 10 + sizeScale(d.value) * multiplier)
            .spiral('archimedean')
            .on('end', draw)
            .start()
        }
        
        makeCloud('[data-cloud]')
        
    }, [props])

    return (
        <>
            {!props.string && <Loading /> }
            <svg height="100%" width="100%" data-cloud></svg>
        </>
    )
}