import './styles.scss'
import Loading from '../../ui/Loading'

export default function NPS(props) {
    return (
        <>
            {props.score == null && <Loading /> }
            <svg className='nps' viewBox='0 0 250 46' height="100%" width="100%">
                <text y="56%">
                    <tspan className='score' x="0" dy="0" alignmentBaseline='text-top' style={{
                        fill: `var(--${props.color || 'raspberry'})`
                    }}>{props.score}</tspan>
                    <tspan className='score-label' x="0" dy="17" alignmentBaseline='text-top'>NPS</tspan>
                </text>

                <text className='label'>
                    <tspan x="47" y="30%">We boast one of the </tspan>
                    <tspan x="47" dy="15">healthcare industry’s highest</tspan>
                    <tspan x="47" dy="15">Net Promoter Scores!</tspan>
                </text>
            </svg>
        </>
    )
}