import './styles.scss'
import { toPercent, wrapText, truncate, toUSD } from '../../utilities'
import Loading from '../../ui/Loading'

export default function Donut(props) {

    const circumference = 2 * Math.PI * 17
    const diff = (props.val > 1) ? circumference * 1 : circumference * props.val
    const dashOffset = circumference - diff

    return (
        <>
            {props.val == null && <Loading /> }
            <svg className='donut' width="100%" height="100%" viewBox="0 0 40 40" role="img">
                <title>{`${toPercent(props.val)} Savings`}</title>
                
                <circle className="hole" cx="20" cy="20" r="17" fill="transparent" role="presentation"></circle>

                <circle className="segment" cx="20" cy="20" r="17" fill="transparent" strokeWidth="6" strokeDashoffset="25"></circle>

                <circle className="ring" cx="20" cy="20" r="17" fill="transparent" strokeWidth="6" role="presentation" strokeDasharray={circumference} strokeDashoffset={dashOffset} style={{
                    stroke: `var(--${props.color || 'raspberry'})`
                }}></circle>

                <g className="text">
                    <text x="50%" y="50%" textAnchor="middle" className="value">{(props.display) ? toUSD(props.display, 0, true) : toPercent(props.val)}</text>
                    <text x="50%" dy="4" textAnchor="middle" className="label" dangerouslySetInnerHTML={wrapText(truncate(props.label))}></text>
                </g>

            </svg>
        </>
    )
}


