import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import ClientLogo from '../components/ClientLogo'

export default function LogoGrid() {
    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "logo",
                type: "column",
                source: "source",
                allowMultiple: false
            },
            {
                name: "name",
                type: "column",
                source: "source",
                allowMultiple: false
            },
            {
                name: "qty",
                type: "column",
                source: "source",
                allowMultiple: false
            }
        ])
    }, [])

    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { logo, name, qty } = config
    const logoUrls = (Object.keys(sigmaData).length && logo) ? sigmaData[logo] : null
    const orgNames = (Object.keys(sigmaData).length && name) ? sigmaData[name] : null
    const qtyAmount = (Object.keys(sigmaData).length && qty) ? sigmaData[qty][0] : null
    const orgs = []

    if(logoUrls && orgNames && qtyAmount) {
        logoUrls.forEach((logo, idx) => {
            orgs.push(<ClientLogo src={logo} key={idx} label={orgNames[idx]} grayscale={false} />)
        })
    }   

    return (
        <section style={
            {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '5vw',
                padding: 'var(--gap-sm)'
            }
        }>
            {orgs.slice(0, qtyAmount || 3)}
        </section>
    )
}