const colors = {
    blueJeans: 'hsl(213, 100%, 25%)',
    orangeSlice: 'hsl(39, 100%, 50%)',
    blueSkies: 'hsl(203, 100%, 50%)',
    cyan: 'hsl(194, 100%, 50%)',
    raspberry: 'hsl(328, 100%, 50%)',
    blueberry: 'hsl(213, 100%, 39%)',
    eggplant: 'hsl(277, 78%, 41%)',
    violet: 'hsl(310, 100%, 41%)',
    tuscany: 'hsl(53, 100%, 50%)',
    ocean: 'hsl(201, 100%, 38%)',
    fuchsia: 'hsl(316, 100%, 45%)',
    cherry: 'hsl(0, 100%, 50%)',
    inkBlack: 'hsl(240, 100%, 2%)',
    marker: 'hsl(240, 20%, 8%)',
    licorice: 'hsl(200, 13%, 9%)',
    gunmetal: 'hsl(240, 2%, 25%)',
    lightGray: 'hsl(0, 0%, 50%)',
    mist: 'hsl(210, 1%, 65%)',
    clouds: 'hsl(210, 1%, 91%)',
    ice: 'hsl(210, 1%, 97%)',
    white: '#ffffff'
}

export { colors }
