import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompDonut from '../components/Donut/'
import { colorsArr } from '../utilities'
  
export default function App() {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "percent",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "displayValue",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: "label",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
                name: 'color',
                type: 'dropdown',
                values: colorsArr
            }
        ])
    }, [])
        
    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { percent, color, label, displayValue } = config
    const val = (Object.keys(sigmaData).length && percent) ? sigmaData[percent][0] : null
    const display = (Object.keys(sigmaData).length && displayValue) ? sigmaData[displayValue][0] : null
    const labelTxt = (Object.keys(sigmaData).length && label) ? sigmaData[label][0] : 'Label'

    return (
        <CompDonut val={val} display={display} label={labelTxt} color={color} />
    )
}
