import { client, useConfig, useElementData } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompNps from '../components/NPS/'
import { colorsArr } from '../utilities'
  
  export default function App() {
    
    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: "source",
                type: "element",
            },
            {
                name: "score",
                type: "column",
                source: "source",
                allowMultiple: false,
            },
            {
              name: 'color',
              type: 'dropdown',
              values: colorsArr
            },
        ])
    }, [])

    const config = useConfig()
    const sigmaData = useElementData(config.source)

    const { score, color } = config
    const val = (Object.keys(sigmaData).length && score) ? sigmaData[score][0] : 94
    
    return (
      <CompNps score={val} color={color} />
    )
  }
