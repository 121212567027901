import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import Features from './routes/Features'
import Dot from './routes/Dot'
import SingleMetric from './routes/SingleMetric'
import NPS from './routes/NPS'
import SimpleSteps from './routes/SimpleSteps'
import Heading from './routes/Heading'
import Donut from './routes/Donut'
import TitledHeader from './routes/TitledHeader'
import Markets from './routes/Markets'
import Choropleth from './routes/Choropleth'
import WordCloud from './routes/WordCloud'
import LogoGrid from './routes/LogoGrid'
import { scaleContentOnWidth } from './utilities'

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/features' element={<Features />} />
      <Route path='/dot' element={<Dot />} />
      <Route path='/single-metric' element={<SingleMetric />} />
      <Route path='/nps' element={<NPS />} />
      <Route path='/simple-steps' element={<SimpleSteps />} />
      <Route path='/heading' element={<Heading />} />
      <Route path='/donut' element={<Donut />} />
      <Route path='/titled-header' element={<TitledHeader />} />
      <Route path='/choropleth' element={<Choropleth />} />
      <Route path='/markets' element={<Markets />} />
      <Route path='/word-cloud' element={<WordCloud />} />
      <Route path='/logo-grid' element={<LogoGrid />} />
      <Route path='*' element={
        <div>
          <h1 style={{textAlign: 'center'}}>Component Not Found</h1>
          <p style={{textAlign: 'center'}}>Invalid Path</p>
        </div>
      } 
      />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
)

scaleContentOnWidth()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
