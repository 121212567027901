import { client, useConfig } from '@sigmacomputing/plugin'
import { useEffect } from 'react'
import CompHeading from '../components/Heading/'
import { colorsArr } from '../utilities'
  
export default function App(props) {

    useEffect(() => {
        client.config.configureEditorPanel([
            {
                name: 'title',
                type: 'text',
                placeholder: 'Title Text'
            },
            {
                name: 'colorName',
                type: 'dropdown',
                values: colorsArr
            }
        ])
    }, [])
        
    const config = useConfig()

    const { title, colorName } = config
    const txt = (title) ? title : 'Heading Text'
    const fontColor = (colorName) ? colorName : 'blue-jeans'

    return (
        <CompHeading txt={txt} color={fontColor} />
    )
}
