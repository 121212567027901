import './styles.scss'
import { truncate } from '../../utilities'

export default function Heading(props) {

    const fillColor = `var(--${props.color})`

    return (
        <svg className='heading' width="100%" height="100%" viewBox='0 0 400 60'>
            <text x="50%" y="36" textAnchor='middle' title={props.txt} style={{
                fill: fillColor
            }}>{truncate(props.txt)}</text>
        </svg>
    )
}